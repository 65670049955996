<template>
  <wk2 v-if="e.mode === 'wk_score4' || e.mode === 'wk_4aus5' || e.mode === 'test'" :id="id" :e="e" :r="r" :df="df" />
  <lf v-else-if="e.mode === 'lp_3aus4' || e.mode === 'lf_4aus5' || e.mode === 'lf_4aus4'" :id="id" :e="e" :r="r" :df="df"></lf>
  <v-container v-else fluid>
    Event-Modus unbekannt
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'pdf',

  components: {
    Wk2: () => import('./pdf/wk2'),
    Lf: () => import('./pdf/lf')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      default: () => ([])
    },
    df: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
